import './App.css';

import logo from '../src/img/logo.png'
import produto from '../src/img/produtos.png'
import whats from '../src/img/whats.png'
import insta from '../src/img/insta.png'

function App() {
  return (
    <div className="App">
      <div className='divImg'>
        <img src={logo} />



        <div className='divProduto'>
          <img src={produto} />
        </div>

        <section>
          <h4>Mais visibilidade para seu negócio (Modelos)</h4>
        </section>

        <ul>
          <li > <a href='https://fastcar-locacao.vercel.app/' target='_blank'> Sistemas para locação de veículos <small className='new'>new</small> </a > </li>
          <li > <a href='https://systemchamados.netlify.app/' target='_blank'> Sistemas para chamados <small className='new'>new</small> </a > </li>
          <li > <a href='https://iperium-sex-shop.netlify.app/' target='_blank'> Modelo de E-comercer </a > </li>
          {/* <li > <a href='https://iperium-sex-shop.netlify.app/' target='_blank'> Modelo de E-comercer </a > - <small className='new'>Site Ativo!</small> </li> */}
          <li><a href='https://cardapio-dig.netlify.app/' target='_blank'> Cardápio / Delivery Digital  </a> </li>
          {/* <li><a href='https://cardapio-dig.netlify.app/' target='_blank'> Cardápio / Delivery Digital  </a> - <small className='new'>new</small> </li> */}
          <li><a href='https://vitrine-cars.netlify.app' target='_blank'> Vitrine de Carros </a></li>
          <li><a href='https://catalago.netlify.app' target='_blank'>  Catálago Digital </a></li>
        </ul>

        <section>
          <h4>Jogos e Diversão (Modelos) </h4>
        </section>

        <ul>
          <li><a href='https://ranking-of-thrones.netlify.app/' target='_blank'> Sistema de votação com Rank - <small className='new'>new</small> <small>"Baseado na série Game Of Thrones" </small> </a></li>
          <li><a href='https://quizz-dev.netlify.app' target='_blank'> Crie seu Quiz </a></li>
          <li><a href='https://dragonmemory.netlify.app' target='_blank'> Crie um Jogo da Memória </a></li>
        </ul>


        <div className='divPersonalizar'>
          <small>Todos os modelos podem ser personalizados!</small>
        </div>

        <div className='divContato'>
          <div className='contatoTitulo'>
            <h1>Fale conosco!</h1>
          </div>


          <div className='contatoImg'>
            {/* <a href={`//api.whatsapp.com/send?phone=+5588992006856&text=Ol%C3%A1%20gostaria%20de%20saber%20mais%20sobre%20seus%20servi%C3%A7os`} target='blank'> <img style={{ width: '80px' }} src={whats} /></a> */}
            <a href="https://www.instagram.com/f.fsolutions/" target='blank'> <img style={{ width: '80px' }} src={insta} /></a>

          </div>
        </div>
      </div>

    </div>
  );
}

export default App;


